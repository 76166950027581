import { default as _chk2E7RxDkKxUMeta } from "/usr/src/app/pages/_chk.vue?macro=true";
import { default as accessTgt05Kv18rMeta } from "/usr/src/app/pages/clinics/[clinicId]/access.vue?macro=true";
import { default as _91id_93m2maFcKs7EMeta } from "/usr/src/app/pages/clinics/[clinicId]/articles/[id].vue?macro=true";
import { default as _91uuid_93SmOH89kkEmMeta } from "/usr/src/app/pages/clinics/[clinicId]/articles/preview/[uuid].vue?macro=true";
import { default as indexW9QqWqxQwkMeta } from "/usr/src/app/pages/clinics/[clinicId]/doctors/index.vue?macro=true";
import { default as indexsqDtmtBNQMMeta } from "/usr/src/app/pages/clinics/[clinicId]/index.vue?macro=true";
import { default as _91id_93myhlqU5COXMeta } from "/usr/src/app/pages/clinics/[clinicId]/medical_cases/[id].vue?macro=true";
import { default as indexPSQ3JHiOI1Meta } from "/usr/src/app/pages/clinics/[clinicId]/menus/index.vue?macro=true";
import { default as _91id_93I5oP7L9f4xMeta } from "/usr/src/app/pages/clinics/[clinicId]/menus/treatments/[id].vue?macro=true";
import { default as menusARjelM2wISMeta } from "/usr/src/app/pages/clinics/[clinicId]/menus.vue?macro=true";
import { default as _91id_93lmwbpDQMTmMeta } from "/usr/src/app/pages/clinics/[clinicId]/reports/[id].vue?macro=true";
import { default as indexifgGwmc7PrMeta } from "/usr/src/app/pages/clinics/[clinicId]/reports/index.vue?macro=true";
import { default as _91clinicId_936sTXoiRJcrMeta } from "/usr/src/app/pages/clinics/[clinicId].vue?macro=true";
import { default as searchfCcyMdpXdGMeta } from "/usr/src/app/pages/clinics/search.vue?macro=true";
import { default as _91id_93VToY3Jt9bzMeta } from "/usr/src/app/pages/doctors/[id].vue?macro=true";
import { default as indexC1ui74KIXQMeta } from "/usr/src/app/pages/f-[fSlug]/index.vue?macro=true";
import { default as indexuh8FHo4E8aMeta } from "/usr/src/app/pages/f-[fSlug]/p-[pSlug]/index.vue?macro=true";
import { default as indexFEPBLI2OOFMeta } from "/usr/src/app/pages/f-[fSlug]/p-[pSlug]/s-[sSlug]/index.vue?macro=true";
import { default as indexLnFXoGqFLiMeta } from "/usr/src/app/pages/f-[fSlug]/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexwGFAQZ7LzIMeta } from "/usr/src/app/pages/l-[lSlug]/index.vue?macro=true";
import { default as indexpdHie6rBcxMeta } from "/usr/src/app/pages/l-[lSlug]/p-[pSlug]/index.vue?macro=true";
import { default as indexHglBKdzRAsMeta } from "/usr/src/app/pages/l-[lSlug]/p-[pSlug]/s-[sSlug]/index.vue?macro=true";
import { default as index8ADoWCw2XIMeta } from "/usr/src/app/pages/l-[lSlug]/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue?macro=true";
import { default as indexK7fU3PLfqOMeta } from "/usr/src/app/pages/list-subjects/index.vue?macro=true";
import { default as a_45_91featureType_93rNLTIsZlB3Meta } from "/usr/src/app/pages/media/a-[featureType].vue?macro=true";
import { default as _91id_93S9QYwMe3pVMeta } from "/usr/src/app/pages/media/columns/[id].vue?macro=true";
import { default as _91uuid_93HeN57uxdSaMeta } from "/usr/src/app/pages/media/columns/preview/[uuid].vue?macro=true";
import { default as _91id_93NuyCuNk8lvMeta } from "/usr/src/app/pages/media/fixed/[id].vue?macro=true";
import { default as _91uuid_93HbhYnZmt34Meta } from "/usr/src/app/pages/media/fixed/preview/[uuid].vue?macro=true";
import { default as indexnaMairafq4Meta } from "/usr/src/app/pages/media/index.vue?macro=true";
import { default as _91id_93gO3iTzjP72Meta } from "/usr/src/app/pages/media/news/[id].vue?macro=true";
import { default as _91uuid_93491OptRSHvMeta } from "/usr/src/app/pages/media/news/preview/[uuid].vue?macro=true";
import { default as _91id_93rNx9gx05XyMeta } from "/usr/src/app/pages/media/treatment_column/articles/[id].vue?macro=true";
import { default as _91id_93Z0dO6vGih5Meta } from "/usr/src/app/pages/media/treatment_column/categories/[id].vue?macro=true";
import { default as indexSoaryvfQu2Meta } from "/usr/src/app/pages/media/treatment_column/index.vue?macro=true";
import { default as indexInDhHQwCLoMeta } from "/usr/src/app/pages/mypage/account-setting/email/index.vue?macro=true";
import { default as successafyaBC9YvKMeta } from "/usr/src/app/pages/mypage/account-setting/email/success.vue?macro=true";
import { default as indexi1w3CCjfFZMeta } from "/usr/src/app/pages/mypage/account-setting/index.vue?macro=true";
import { default as indexWs94m0TJyCMeta } from "/usr/src/app/pages/mypage/account-setting/password/index.vue?macro=true";
import { default as clinic2kRFkKZOWbMeta } from "/usr/src/app/pages/mypage/browse-history/clinic.vue?macro=true";
import { default as browse_45history7vrUZFSymyMeta } from "/usr/src/app/pages/mypage/browse-history.vue?macro=true";
import { default as homeQXPVoRbJUVMeta } from "/usr/src/app/pages/mypage/home.vue?macro=true";
import { default as profilepYX6CziVKJMeta } from "/usr/src/app/pages/mypage/profile.vue?macro=true";
import { default as mypage557azitGk9Meta } from "/usr/src/app/pages/mypage.vue?macro=true";
import { default as fmcs_001YJxOBntS48Meta } from "/usr/src/app/pages/online-clinic/fmcs_001.vue?macro=true";
import { default as indexuwtvNdvEx6Meta } from "/usr/src/app/pages/online-clinic/index.vue?macro=true";
import { default as mdb_001fGAhvtRlsRMeta } from "/usr/src/app/pages/online-clinic/mdb_001.vue?macro=true";
import { default as indexa3gp4f53QEMeta } from "/usr/src/app/pages/p-[pSlug]/index.vue?macro=true";
import { default as indexp4goYcBkSgMeta } from "/usr/src/app/pages/p-[pSlug]/s-[sSlug]/index.vue?macro=true";
import { default as indexT9KcwNHbclMeta } from "/usr/src/app/pages/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue?macro=true";
import { default as _91id_93j3sHgIe5zsMeta } from "/usr/src/app/pages/question-keywords/[id].vue?macro=true";
import { default as indexaEqVsBMd8qMeta } from "/usr/src/app/pages/question-keywords/index.vue?macro=true";
import { default as _91id_93ebs6GB9JyYMeta } from "/usr/src/app/pages/questions/[id].vue?macro=true";
import { default as w_45_91wId_93jhjulkqQcFMeta } from "/usr/src/app/pages/reports/w-[wId].vue?macro=true";
import { default as wordsyM7Kb5IimSMeta } from "/usr/src/app/pages/reports/words.vue?macro=true";
import { default as clinicOO09ykR2q2Meta } from "/usr/src/app/pages/saved-lists/clinic.vue?macro=true";
import { default as menuakIFClg6MiMeta } from "/usr/src/app/pages/saved-lists/menu.vue?macro=true";
import { default as saved_45listsX4wcU1O6AAMeta } from "/usr/src/app/pages/saved-lists.vue?macro=true";
import { default as loginJKUTio6jWoMeta } from "/usr/src/app/pages/session/login.vue?macro=true";
import { default as registerX9YSWBVUocMeta } from "/usr/src/app/pages/session/register.vue?macro=true";
import { default as sessionrm4UNBnTGlMeta } from "/usr/src/app/pages/session.vue?macro=true";
import { default as indexMAdrH79wa7Meta } from "/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/index.vue?macro=true";
import { default as indexLJHQDW0YNgMeta } from "/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/p-[pSlug]/index.vue?macro=true";
import { default as indexZyDMUWcxk1Meta } from "/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/p-[pSlug]/s-[sSlug]/index.vue?macro=true";
import { default as indexhELQQYzToqMeta } from "/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue?macro=true";
import { default as test_45auth_45f91145ce_4565a8_4543e0_45af6e_45f10f06a07708WhFLca5APZMeta } from "/usr/src/app/pages/test-auth-f91145ce-65a8-43e0-af6e-f10f06a07708.vue?macro=true";
export default [
  {
    name: "_chk",
    path: "/_chk",
    component: () => import("/usr/src/app/pages/_chk.vue")
  },
  {
    name: _91clinicId_936sTXoiRJcrMeta?.name,
    path: "/clinics/:clinicId()",
    meta: _91clinicId_936sTXoiRJcrMeta || {},
    component: () => import("/usr/src/app/pages/clinics/[clinicId].vue"),
    children: [
  {
    name: "clinics-clinicId-access",
    path: "access",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/access.vue")
  },
  {
    name: "clinics-clinicId-articles-id",
    path: "articles/:id()",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/articles/[id].vue")
  },
  {
    name: "clinics-clinicId-articles-preview-uuid",
    path: "articles/preview/:uuid()",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/articles/preview/[uuid].vue")
  },
  {
    name: "clinics-clinicId-doctors",
    path: "doctors",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/doctors/index.vue")
  },
  {
    name: "clinics-clinicId",
    path: "",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/index.vue")
  },
  {
    name: "clinics-clinicId-medical_cases-id",
    path: "medical_cases/:id()",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/medical_cases/[id].vue")
  },
  {
    name: menusARjelM2wISMeta?.name,
    path: "menus",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/menus.vue"),
    children: [
  {
    name: "clinics-clinicId-menus",
    path: "",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/menus/index.vue")
  },
  {
    name: "clinics-clinicId-menus-treatments-id",
    path: "treatments/:id()",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/menus/treatments/[id].vue")
  }
]
  },
  {
    name: "clinics-clinicId-reports-id",
    path: "reports/:id()",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/reports/[id].vue")
  },
  {
    name: "clinics-clinicId-reports",
    path: "reports",
    component: () => import("/usr/src/app/pages/clinics/[clinicId]/reports/index.vue")
  }
]
  },
  {
    name: "clinics-search",
    path: "/clinics/search",
    component: () => import("/usr/src/app/pages/clinics/search.vue")
  },
  {
    name: "doctors-id",
    path: "/doctors/:id()",
    meta: _91id_93VToY3Jt9bzMeta || {},
    component: () => import("/usr/src/app/pages/doctors/[id].vue")
  },
  {
    name: "f-fSlug",
    path: "/f-:fSlug()",
    meta: indexC1ui74KIXQMeta || {},
    component: () => import("/usr/src/app/pages/f-[fSlug]/index.vue")
  },
  {
    name: "f-fSlug-p-pSlug",
    path: "/f-:fSlug()/p-:pSlug()",
    meta: indexuh8FHo4E8aMeta || {},
    component: () => import("/usr/src/app/pages/f-[fSlug]/p-[pSlug]/index.vue")
  },
  {
    name: "f-fSlug-p-pSlug-s-sSlug",
    path: "/f-:fSlug()/p-:pSlug()/s-:sSlug()",
    meta: indexFEPBLI2OOFMeta || {},
    component: () => import("/usr/src/app/pages/f-[fSlug]/p-[pSlug]/s-[sSlug]/index.vue")
  },
  {
    name: "f-fSlug-p-pSlug-s-sSlug-st-stSlug",
    path: "/f-:fSlug()/p-:pSlug()/s-:sSlug()/st-:stSlug()",
    meta: indexLnFXoGqFLiMeta || {},
    component: () => import("/usr/src/app/pages/f-[fSlug]/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "l-lSlug",
    path: "/l-:lSlug()",
    meta: indexwGFAQZ7LzIMeta || {},
    component: () => import("/usr/src/app/pages/l-[lSlug]/index.vue")
  },
  {
    name: "l-lSlug-p-pSlug",
    path: "/l-:lSlug()/p-:pSlug()",
    meta: indexpdHie6rBcxMeta || {},
    component: () => import("/usr/src/app/pages/l-[lSlug]/p-[pSlug]/index.vue")
  },
  {
    name: "l-lSlug-p-pSlug-s-sSlug",
    path: "/l-:lSlug()/p-:pSlug()/s-:sSlug()",
    meta: indexHglBKdzRAsMeta || {},
    component: () => import("/usr/src/app/pages/l-[lSlug]/p-[pSlug]/s-[sSlug]/index.vue")
  },
  {
    name: "l-lSlug-p-pSlug-s-sSlug-st-stSlug",
    path: "/l-:lSlug()/p-:pSlug()/s-:sSlug()/st-:stSlug()",
    meta: index8ADoWCw2XIMeta || {},
    component: () => import("/usr/src/app/pages/l-[lSlug]/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue")
  },
  {
    name: "list-subjects",
    path: "/list-subjects",
    component: () => import("/usr/src/app/pages/list-subjects/index.vue")
  },
  {
    name: "media-a-featureType",
    path: "/media/a-:featureType()",
    meta: a_45_91featureType_93rNLTIsZlB3Meta || {},
    component: () => import("/usr/src/app/pages/media/a-[featureType].vue")
  },
  {
    name: "media-columns-id",
    path: "/media/columns/:id()",
    component: () => import("/usr/src/app/pages/media/columns/[id].vue")
  },
  {
    name: "media-columns-preview-uuid",
    path: "/media/columns/preview/:uuid()",
    component: () => import("/usr/src/app/pages/media/columns/preview/[uuid].vue")
  },
  {
    name: "media-fixed-id",
    path: "/media/fixed/:id()",
    component: () => import("/usr/src/app/pages/media/fixed/[id].vue")
  },
  {
    name: "media-fixed-preview-uuid",
    path: "/media/fixed/preview/:uuid()",
    component: () => import("/usr/src/app/pages/media/fixed/preview/[uuid].vue")
  },
  {
    name: "media",
    path: "/media",
    meta: indexnaMairafq4Meta || {},
    component: () => import("/usr/src/app/pages/media/index.vue")
  },
  {
    name: "media-news-id",
    path: "/media/news/:id()",
    component: () => import("/usr/src/app/pages/media/news/[id].vue")
  },
  {
    name: "media-news-preview-uuid",
    path: "/media/news/preview/:uuid()",
    component: () => import("/usr/src/app/pages/media/news/preview/[uuid].vue")
  },
  {
    name: "media-treatment_column-articles-id",
    path: "/media/treatment_column/articles/:id()",
    component: () => import("/usr/src/app/pages/media/treatment_column/articles/[id].vue")
  },
  {
    name: "media-treatment_column-categories-id",
    path: "/media/treatment_column/categories/:id()",
    meta: _91id_93Z0dO6vGih5Meta || {},
    component: () => import("/usr/src/app/pages/media/treatment_column/categories/[id].vue")
  },
  {
    name: "media-treatment_column",
    path: "/media/treatment_column",
    meta: indexSoaryvfQu2Meta || {},
    component: () => import("/usr/src/app/pages/media/treatment_column/index.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: mypage557azitGk9Meta || {},
    component: () => import("/usr/src/app/pages/mypage.vue"),
    children: [
  {
    name: "mypage-account-setting-email",
    path: "account-setting/email",
    component: () => import("/usr/src/app/pages/mypage/account-setting/email/index.vue")
  },
  {
    name: "mypage-account-setting-email-success",
    path: "account-setting/email/success",
    component: () => import("/usr/src/app/pages/mypage/account-setting/email/success.vue")
  },
  {
    name: "mypage-account-setting",
    path: "account-setting",
    component: () => import("/usr/src/app/pages/mypage/account-setting/index.vue")
  },
  {
    name: "mypage-account-setting-password",
    path: "account-setting/password",
    component: () => import("/usr/src/app/pages/mypage/account-setting/password/index.vue")
  },
  {
    name: "mypage-browse-history",
    path: "browse-history",
    component: () => import("/usr/src/app/pages/mypage/browse-history.vue"),
    children: [
  {
    name: "mypage-browse-history-clinic",
    path: "clinic",
    component: () => import("/usr/src/app/pages/mypage/browse-history/clinic.vue")
  }
]
  },
  {
    name: "mypage-home",
    path: "home",
    component: () => import("/usr/src/app/pages/mypage/home.vue")
  },
  {
    name: "mypage-profile",
    path: "profile",
    component: () => import("/usr/src/app/pages/mypage/profile.vue")
  }
]
  },
  {
    name: "online-clinic-fmcs_001",
    path: "/online-clinic/fmcs_001",
    meta: fmcs_001YJxOBntS48Meta || {},
    component: () => import("/usr/src/app/pages/online-clinic/fmcs_001.vue")
  },
  {
    name: "online-clinic",
    path: "/online-clinic",
    meta: indexuwtvNdvEx6Meta || {},
    component: () => import("/usr/src/app/pages/online-clinic/index.vue")
  },
  {
    name: "online-clinic-mdb_001",
    path: "/online-clinic/mdb_001",
    meta: mdb_001fGAhvtRlsRMeta || {},
    component: () => import("/usr/src/app/pages/online-clinic/mdb_001.vue")
  },
  {
    name: "p-pSlug",
    path: "/p-:pSlug()",
    meta: indexa3gp4f53QEMeta || {},
    component: () => import("/usr/src/app/pages/p-[pSlug]/index.vue")
  },
  {
    name: "p-pSlug-s-sSlug",
    path: "/p-:pSlug()/s-:sSlug()",
    meta: indexp4goYcBkSgMeta || {},
    component: () => import("/usr/src/app/pages/p-[pSlug]/s-[sSlug]/index.vue")
  },
  {
    name: "p-pSlug-s-sSlug-st-stSlug",
    path: "/p-:pSlug()/s-:sSlug()/st-:stSlug()",
    meta: indexT9KcwNHbclMeta || {},
    component: () => import("/usr/src/app/pages/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue")
  },
  {
    name: "question-keywords-id",
    path: "/question-keywords/:id()",
    component: () => import("/usr/src/app/pages/question-keywords/[id].vue")
  },
  {
    name: "question-keywords",
    path: "/question-keywords",
    component: () => import("/usr/src/app/pages/question-keywords/index.vue")
  },
  {
    name: "questions-id",
    path: "/questions/:id()",
    component: () => import("/usr/src/app/pages/questions/[id].vue")
  },
  {
    name: "reports-w-wId",
    path: "/reports/w-:wId()",
    component: () => import("/usr/src/app/pages/reports/w-[wId].vue")
  },
  {
    name: "reports-words",
    path: "/reports/words",
    component: () => import("/usr/src/app/pages/reports/words.vue")
  },
  {
    name: "saved-lists",
    path: "/saved-lists",
    component: () => import("/usr/src/app/pages/saved-lists.vue"),
    children: [
  {
    name: "saved-lists-clinic",
    path: "clinic",
    component: () => import("/usr/src/app/pages/saved-lists/clinic.vue")
  },
  {
    name: "saved-lists-menu",
    path: "menu",
    component: () => import("/usr/src/app/pages/saved-lists/menu.vue")
  }
]
  },
  {
    name: "session",
    path: "/session",
    component: () => import("/usr/src/app/pages/session.vue"),
    children: [
  {
    name: "session-login",
    path: "login",
    meta: loginJKUTio6jWoMeta || {},
    component: () => import("/usr/src/app/pages/session/login.vue")
  },
  {
    name: "session-register",
    path: "register",
    meta: registerX9YSWBVUocMeta || {},
    component: () => import("/usr/src/app/pages/session/register.vue")
  }
]
  },
  {
    name: "tc-tcSlug-t-tSlug",
    path: "/tc-:tcSlug()/t-:tSlug()",
    meta: indexMAdrH79wa7Meta || {},
    component: () => import("/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/index.vue")
  },
  {
    name: "tc-tcSlug-t-tSlug-p-pSlug",
    path: "/tc-:tcSlug()/t-:tSlug()/p-:pSlug()",
    meta: indexLJHQDW0YNgMeta || {},
    component: () => import("/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/p-[pSlug]/index.vue")
  },
  {
    name: "tc-tcSlug-t-tSlug-p-pSlug-s-sSlug",
    path: "/tc-:tcSlug()/t-:tSlug()/p-:pSlug()/s-:sSlug()",
    meta: indexZyDMUWcxk1Meta || {},
    component: () => import("/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/p-[pSlug]/s-[sSlug]/index.vue")
  },
  {
    name: "tc-tcSlug-t-tSlug-p-pSlug-s-sSlug-st-stSlug",
    path: "/tc-:tcSlug()/t-:tSlug()/p-:pSlug()/s-:sSlug()/st-:stSlug()",
    meta: indexhELQQYzToqMeta || {},
    component: () => import("/usr/src/app/pages/tc-[tcSlug]/t-[tSlug]/p-[pSlug]/s-[sSlug]/st-[stSlug]/index.vue")
  },
  {
    name: "test-auth-f91145ce-65a8-43e0-af6e-f10f06a07708",
    path: "/test-auth-f91145ce-65a8-43e0-af6e-f10f06a07708",
    component: () => import("/usr/src/app/pages/test-auth-f91145ce-65a8-43e0-af6e-f10f06a07708.vue")
  }
]